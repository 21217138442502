import React, { useState, useEffect } from "react";
import { useModal } from "../context/modalContext";
import "../scss/styles.scss";

const ModalCTA = () => {
   const { showModal, setShowModal } = useModal();
   const [isAnimatingOut, setIsAnimatingOut] = useState(false);
   const { idDevTeam } = useModal();

   const handleClose = () => {
      setIsAnimatingOut(true);
   };

   useEffect(() => {
      let timeoutId;
      if (isAnimatingOut) {
         timeoutId = setTimeout(() => {
            setShowModal(false);
            setIsAnimatingOut(false);
         }, 200);
      }
      return () => clearTimeout(timeoutId);
   }, [isAnimatingOut, setShowModal]);

   const handleModalContentClick = (e) => {
      e.stopPropagation();
   };

   if (!showModal && !isAnimatingOut) return null;

   return (
      <div
         className={`modal-cta ${isAnimatingOut ? "fadeOut" : ""}`}
         onClick={handleClose}
      >
         <div
            className={`modal-cta-content ${
               isAnimatingOut ? "slideOutDown" : "bounceInUp"
            }`}
            onClick={handleModalContentClick}
         >
            <div className="modal-cta-content-txt">
               <h2 className="modal-cta-title">
                  Prenons 15 minutes pour parler de votre projet.
               </h2>
               <p className="modal-cta-txt">
                  Selon vos besoins et vos objectifs, nous vous proposerons une
                  solution sur-mesure.
               </p>
            </div>

            <iframe
               src={`https://meetings-eu1.hubspot.com/meetings/${idDevTeam}?embed=true&locale=fr`}
               className="meetings-iframe-container"
            />
         </div>
      </div>
   );
};

export default ModalCTA;
