import { createContext, useContext } from "react";
import React, { useState } from "react";
import ModalCTA from "../components/modal";
import "../scss/styles.scss";
export const ModalContext = createContext();

export function ModalContextProvider(props) {
   const [showModal, setShowModal] = useState(false);
   const [idDevTeam, setIdDevTeam] = useState("antony-hoell");

   return (
      <ModalContext.Provider
         value={{ showModal, setShowModal, idDevTeam, setIdDevTeam }}
      >
         <ModalCTA />
         {props.children}
      </ModalContext.Provider>
   );
}

export const useModal = () => {
   const context = useContext(ModalContext);
   if (!context) {
      throw new Error("useModal s'utilise avec ModalContext.Provider");
   }

   return context;
};
