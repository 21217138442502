import React from "react";

import "./src/scss/styles.scss";
import { ModalContextProvider } from "./src/context/modalContext";
import JobForm from "./src/components/JobForm/JobForm";

const defaultTheme = "light";
export const ThemeContext = React.createContext(defaultTheme);
export const wrapRootElement = ({ element }) => {
  return (
    <ThemeContext.Provider value={defaultTheme}>
      <ModalContextProvider>
        <JobForm title="Candidature spontannée" />
        {element}
      </ModalContextProvider>
    </ThemeContext.Provider>
  );
};

export const onClientEntry = () => {
  window.firstLoaded = true;
};
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.hash && location.hash.includes("#!")) {
    window.location.replace("/404");
  }
  if (prevLocation !== null) {
    window.firstLoaded = false;
  }
};
